import React, { useState, useEffect } from "react"
import { isLoggedIn } from "../utils/auth"
import firebase from "../utils/database"
import { navigate } from "gatsby"
import Status from "../components/Status"
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import './Weave-Admin.css'


const useItems = () => {
  const [items, setItems] = useState([])
  useEffect(() => {
    //useEffect is a way to do Component Did Mount in functional components. Need to do this outside of render
    if (!isLoggedIn()) {
      // If we’re not logged in, redirect to the login page.
      navigate(`/login/`)
      return null
    }
    const unsubscribe = firebase
      .firestore()
      .collection("launch_challenge_2020")
      .orderBy("created")
      .onSnapshot(snapshot => {
        const listItems = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        //console.warn(listItems)
        setItems(listItems)
      })
    //called the unsubscribe--closing connection to Firestore.
    return () => unsubscribe()
  }, [])
  return items
}

//render() {
const WeaveAdmin = () => {
  const challengeData = useItems();

  //const { profileObj } = getCurrentUser()
  //console.warn({ profileObj })

  return (
    <>
      <Layout>
        <Helmet>
          <title>Login</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <Status />
        <section className="section thick">
          <div className="container skinny Center">
            <table>
              <thead>
                <tr>

                  <th className="hide">id</th>
                  <th>Website</th>
                  <th>Email</th>
                  <th>Social Handles</th>
                  <th>Receipt</th>
                </tr>

              </thead>
              {challengeData.map(item => (
                <tbody key={item.id}>
                  <tr>
                    <td className="hide">{item.id}</td>
                    <td>{item.website}</td>
                    <td>{item.email}</td>
                    <td>{item.socialHandle}</td>
                    <td><a href={item.receiptUrl} target="_blank">Receipt</a></td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default WeaveAdmin
