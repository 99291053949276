import React from "react"
import { /*Link,*/ navigate } from "@reach/router"
import { getCurrentUser, isLoggedIn, logout } from "../../utils/auth"
import styles from "./status.module.css"

export default () => {
  let details
  if (!isLoggedIn()) {
    details = (
      <p className={styles[`status__text`]}>
        {/* You must have getweave.com credentials to use this app!
        {` `}
        <Link to="/login">log in</Link>. */}
      </p>
    )
  } else {
    const { profileObj } = getCurrentUser()

    details = (
      <div className={styles[`status__text`]}>
        <img className={styles.google__img} src={profileObj.imageUrl} alt={profileObj.name} />
        <span className={styles.status__text_t}>Welcome, {profileObj.name} &nbsp;&nbsp;
          <a
            href="/"
            onClick={event => {
              event.preventDefault()
              logout(() => navigate(`/login/`))
            }}> Sign Out </a>
        </span>
      </div>
    )

  }
  return <div className={styles.status}>{details}</div>
}
